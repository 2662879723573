'use strict'

$(document).ready(() => {

    let $page = $('.js-service-landing')
    if ($page.length === 0) {
        return
    }

  const validNumber = (className) => {
    const selectedElement = $(className)
    if (selectedElement &&
      selectedElement.length > 0 &&
      selectedElement.outerHeight() &&      
      !Number.isNaN(selectedElement.outerHeight())) {      
        return selectedElement.outerHeight()
    }
    return 0
  }

    const scrollToTarget = (id) => {
        let el = $(`#${id}`)           
        if (el && el.length > 0) {
            $('html, body').animate({
              scrollTop: el.offset().top - (validNumber('.site-header') + validNumber('.jump-links') + 36)//36px for margin-top on components
            }, 500)
        }
    }

    const checkSticky = () => {
        let $jumplinks = $('.jump-links')
        let $jumpLinksPosition = $('.jump-links__position')
        let windowTop = $(window).scrollTop() + (validNumber('.site-header') + validNumber('.site-subnav'))
        let stickyTop = $jumpLinksPosition.offset().top
        if ($('.site-subnav').length) {
            $jumplinks.addClass('jump-links--padding')
        }
        if (windowTop > stickyTop) {
            $jumplinks.addClass('jump-links--fixed')
            $jumpLinksPosition.css('height', $jumplinks.outerHeight())
        } else {
            $jumplinks.removeClass('jump-links--fixed')
            $jumpLinksPosition.css('height', 0)
        }
    }

    let $jumpLinkButtons = $('.jump-link--js')
    $jumpLinkButtons.on('click', (event) => {
        $('.jump-links__link').removeClass('jump-links__link--active')
        $(event.currentTarget).addClass('jump-links__link--active')
        let id = $(event.currentTarget).data('key')
        scrollToTarget(id)
        $(`#${id}`).find('a').eq(0).focus()
    })

    $('.jump-links__select--js').on('change', (event) => {        
        scrollToTarget(event.currentTarget.value)
    })

    let isAzState = false
    let $servicesections = $('.js-service-section')

    $('.js-view-all').on('click', (event) => {
        isAzState = !isAzState

        //disable jump link buttons when viewing all
        $jumpLinkButtons.each((i, elem) => {
            $(elem).removeClass('jump-links__link--active')
            $(elem).prop("disabled", isAzState)
        })        

        let $this = $(event.currentTarget)
        $this.text(isAzState ? $this.data('back-label') : $this.data('all-label'))
        $this.attr('aria-label', isAzState ? $this.data('back-label') : $this.data('all-label'))

        $servicesections.each((i, elem) => {
            $(elem).toggleClass('service-landing__section--hidden')
        })
    })    

    const url = $page.attr('data-search-url')
    let keyword = ''

    $('.search-box__input').on('keyup', function (e) {
        e.preventDefault()
        e.stopPropagation()
        keyword = this.value
        if (e.keyCode == 13 && keyword.length > 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword) + '&section=services'
        }
    })
    
    $('.search-box__button').click(function (e) {
        e.preventDefault()
        e.stopPropagation()        
        if (keyword.length > 0) {
            window.location.href = url + '?keyword=' + encodeURIComponent(keyword) + '&section=services'
        }
    })    

    $(window).on('scroll', checkSticky)

    checkSticky()
})
'use strict'

var util = require('util')
var Typeahead = require('typeahead')
var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null)
var showResults = true

var isArray = (function () {
    // Use compiler's own isArray when available
    if (Array.isArray) {
        return Array.isArray;
    }

    // Retain references to variables for performance
    // optimization
    var objectToStringFn = Object.prototype.toString,
        arrayToStringResult = objectToStringFn.call([]);

    return function (subject) {
        return objectToStringFn.call(subject) === arrayToStringResult;
    };
}());

function GetRequestObject(koObject) {
    var viewModelToUpdate = ko.mapping.toJS(koObject);

    FilterRequestObject(viewModelToUpdate);

    return viewModelToUpdate;
}

function FilterRequestObject(viewModelToUpdate) {
    for (var prop in viewModelToUpdate) {
        var parentProperty = viewModelToUpdate[prop];
        if (isArray(parentProperty)) {
            var arrayCopy = parentProperty.slice(0);
            parentProperty.length = 0;
            for (var i = 0; i < arrayCopy.length; i++) {
                if (typeof (arrayCopy[i].IsSelected) !== 'undefined') {
                    if (HasSelectedFilter(arrayCopy[i])) {
                        parentProperty.push(arrayCopy[i]);
                        FilterRequestObject(arrayCopy[i]);
                    }
                } else {
                    parentProperty.push(arrayCopy[i]);
                }
            };
        } else {
            if (typeof (parentProperty) === 'object') {
                FilterRequestObject(parentProperty);
            }
        }
    }
    return viewModelToUpdate;
}

function HasSelectedFilter(jsObject) {
    if (!!jsObject && typeof (jsObject.IsSelected) !== 'undefined') {
        if (jsObject.IsSelected == true) {
            return true;
        }
    }
    var isSelected = false;
    for (var childProp in jsObject) {
        var childProperty = jsObject[childProp];
        if (isArray(childProperty)) {
            for (var i = 0; i < childProperty.length; i++) {
                if (typeof (childProperty[i]) === 'object' && !!childProperty[i]) {
                    isSelected = HasSelectedFilter(childProperty[i]);
                }
                if (isSelected == true) {
                    break;
                }
            };
        } else {
            if (typeof (childProperty) === 'object') {
                isSelected = HasSelectedFilter(childProperty);
            }
        }
        if (isSelected == true) {
            break;
        }
    }
    return isSelected;
}

function init(option, selector, inputSelector, typeaheadSelector) {
    var defaults = {
        initialJsonData: null,
        serviceUrl: null,
        typeaheadUrl: null,
        keywordPlaceholder: 'Search'
    }
    var option = $.extend({}, defaults, option)

    // Load JSON data from model
    var viewModelJs = JSON.parse(option.initialJsonData)
    var viewModel = ko.mapping.fromJS(viewModelJs)
    var guidEmpty = '00000000-0000-0000-0000-000000000000'
    var pageContent = $(selector)
    var keyWordIDKey = 'KEYWORD'

    viewModel.SelectedIndustryFilter = ko.observable().extend({
        deferred: true
    });
    viewModel.SelectedInitiativesFilter = ko.observable().extend({
        deferred: true
    });
    viewModel.SearchFilters = ko.observableArray([]).extend({
        deferred: true
    });
    viewModel.SelectedCircuitsFilter = ko.observable().extend({
        deferred: true
    });
    viewModel.HasSourceUrl = ko.observable().extend({
        deferred: true
    });

    // Keyword Search
    viewModel.SearchKeyword = ko.observable("")

    viewModel.KeywordEnter = function (data, event) {
        if (event.keyCode === 13) {
            viewModel.SectionSearch.KeywordFilter(event.target.value);
            viewModel.Search();
        }

        return true;
    }

    viewModel.KeywordSearchClick = function () {
        viewModel.SectionSearch.KeywordFilter(viewModel.SearchKeyword());
        viewModel.Search();
    }

    viewModel.SearchResultClick = function () {
      var qs = viewModel.QueryString()
      history.replaceState({ qs: qs }, document.title, qs)
      return true
    }

    // Applied Filters
    viewModel.AppliedFilters = ko.computed(function() {
        var appliedFilters = []

        if (viewModel.SectionSearch && viewModel.SectionSearch.KeywordFilter) {
          if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.KeywordFilter)) {
              if (viewModel.SectionSearch.KeywordFilter() && viewModel.SectionSearch.KeywordFilter().length > 0) {
                  var appliedFilter = {
                      Name: ko.observable(viewModel.SectionSearch.KeywordFilter()),
                      Clear: function() { 
                          viewModel.SectionSearch.KeywordFilter('')
                          viewModel.SearchKeyword('')
                      }
                  }

                  appliedFilters.push(appliedFilter)
              }
          }
        }

        if (viewModel.SectionSearch && viewModel.SectionSearch.FilterSection && viewModel.SectionSearch.FilterSection.FilterGroups) {
          if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.FilterGroups)) {
              _.each(viewModel.SectionSearch.FilterSection.FilterGroups, function (filterGroup) {
                  filterGroup.Filters().forEach(filter => {
                      if (filter.IsSelected()) {
                          var appliedFilter = {
                              Name: ko.observable(filter.Name()),
                              Clear: function() { filter.IsSelected(false) }
                          }

                          appliedFilters.push(appliedFilter)
                      }
                  })
              })
          }
        }

        if (viewModel.SectionSearch && viewModel.SectionSearch.FilterSection && viewModel.SectionSearch.FilterSection.SingleFilters) {
          if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.SingleFilters)) {
              _.each(viewModel.SectionSearch.FilterSection.SingleFilters, function (singleFilter) {
                  if (singleFilter.IsSelected()) {
                      var appliedFilter = {
                          Name: ko.observable(singleFilter.Name()),
                          Clear: function() { singleFilter.IsSelected(false) }
                      }

                      appliedFilters.push(appliedFilter)
                  }
              })
          }
        }

        if (viewModel.SectionSearch && viewModel.SectionSearch.FilterSection && viewModel.SectionSearch.FilterSection.DateFilterGroup) {
          if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.DateFilterGroup)) {
              viewModel.SectionSearch.FilterSection.DateFilterGroup.Filters().forEach(filter => {
                  if (filter.IsSelected()) {
                      var appliedFilter = {
                          Name: ko.observable(filter.Name()),
                          Clear: function() { filter.IsSelected(false) }
                      }

                      appliedFilters.push(appliedFilter)
                  }
              })
          }
        }

        if (viewModel.SectionSearch && viewModel.SectionSearch.FilterSection && viewModel.SectionSearch.FilterSection.QuiTamFilters) {
          if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.QuiTamFilters)) {
              viewModel.SectionSearch.FilterSection.QuiTamFilters.Filters().forEach(filter => {
                  if (filter.IsSelected()) {
                      var appliedFilter = {
                          Name: ko.observable(filter.Name()),
                          Clear: function() { filter.IsSelected(false) }
                      }

                      appliedFilters.push(appliedFilter)
                  }
              })
          }
        }

        return appliedFilters
    })

    viewModel.HasAppliedFilters = function() {
        return viewModel.AppliedFilters().length > 0
    }

    viewModel.HasMultipleAppliedFilters = function() {
        return viewModel.AppliedFilters().length > 1
    }

    viewModel.ClearAppliedFilters = function() {
        viewModel.AppliedFilters().forEach(appliedFilter => {
            appliedFilter.Clear()
        })

        viewModel.Search()
    }

    viewModel.randomColor = function () {
        var colors = ['card-grid__card--light-gray', 'card-grid__card--light-blue', 'card-grid__card--medium-blue', 'card-grid__card--dark-blue']
        return colors[Math.floor(Math.random() * colors.length)]
    };

    viewModel.showSortGroup = function (index) {
      if (index == 0) {
        return true
      }
      else {
        var sortGroup = viewModel.GridData()[index].SortGroup()
        if (viewModel.GridData()[index - 1].SortGroup() !== sortGroup) {
          return true
        }
      }
      return false
    };

    if (viewModel.SectionSearch && viewModel.SectionSearch.SortOptions) {
        viewModel.SectionSearch.SortOptionValue = ko.computed(function() {
            var sortOption = ko.utils.arrayFirst(viewModel.SectionSearch.SortOptions(), function(item) {
                return item.Key() == viewModel.SectionSearch.SortOption()
            })

            if (sortOption) {
                return sortOption.Value()
            }

            return viewModel.SectionSearch.SortOptions()[0].Value();
        })

        viewModel.SortClicked = function(data, event) {
            var value = event.currentTarget.value;
            viewModel.SectionSearch.Skip(0);
            viewModel.IsSorting(true);

            if (viewModel.SectionSearch.SortOption() === value) {
                if (viewModel.SectionSearch.SortDirection() === '0') {
                    viewModel.SectionSearch.SortDirection('1')
                } else {
                    viewModel.SectionSearch.SortDirection('0')
                }
            }
            else {
                  viewModel.SectionSearch.SortOption(value)
                  viewModel.SectionSearch.SortDirection('1')
            }

            viewModel.LoadDataFromServer();
        }
    }

    if (viewModel.SectionSearch && viewModel.SectionSearch.FilterSection && viewModel.SectionSearch.FilterSection.FilterGroups) {
        // Added all the additional observables needed for a filter group to work as a drop-down/select
        _.each(viewModel.SectionSearch.FilterSection.FilterGroups, function (filterGroup, key) {
            filterGroup.HasActiveFilters = ko.computed(function() {
                return filterGroup.Filters().some(x => x.IsSelected())
            }).extend({ deferred: true })

            // Writable computed observable to keep SelectValue in-sync with Filters/IsSelected
            filterGroup.SelectValue = ko.pureComputed({
                read: function () {
                    var selectedIds = filterGroup.Filters().filter(x => x.IsSelected()).map(x => x.ID())

                    if (selectedIds.length > 0) {
                        return selectedIds[0]
                    } else {
                        return null
                    }
                },
                write: function (value) {
                    filterGroup.Filters().forEach(function (filter) {
                        if (filter.ID() == value) {
                            filter.IsSelected(true)
                        } else {
                            filter.IsSelected(false)
                        }
                    })
                },
                owner: viewModel
            }).extend({ deferred: true })

            // Computed value for the drop-down/select label
            filterGroup.SelectLabel = ko.computed(function() {
                if (filterGroup.HasActiveFilters()) {
                    return filterGroup.Filters().filter(x => x.IsSelected()).map(x => x.Name()).join(', ')
                } else {
                    return filterGroup.Label();
                }
            }).extend({ deferred: true })
        })
    }

    viewModel.IsSorting = ko.observable(false)

    viewModel.clearFilters = function (data, event) {
        viewModel.SectionSearch.KeywordFilter("")
        viewModel.SearchKeyword("")
        viewModel.SectionSearch.SortOrder(0)
        viewModel.SectionSearch.Sorting(0)
        viewModel.LoadDataFromServer(true)
    }

    viewModel.updateFilters = function (data, event) {
        viewModel.LoadDataFromServer(true);
        return true;
    }

    viewModel.onEnter = function (d, e) {
        if (e.keyCode === 13) {
            viewModel.SectionSearch.KeywordFilter(e.target.value);
            viewModel.LoadDataFromServer();
        }
        return true;
    }

    viewModel.searchRecoveries = function () {
        var keyword = viewModel.KeywordFilter() ? viewModel.KeywordFilter() : "";
        viewModel.SectionSearch.KeywordFilter(keyword);
        viewModel.LoadDataFromServer();
    };

    window.addEventListener("popstate", (event) => {
        var newQuery = util.parseQueryString();
        viewModel.historyChange(newQuery);
    });

    viewModel.historyChange = function(data) {
        viewModel.SectionSearch.ClearAllSectionSearch()
        viewModel.SectionSearch.Skip(!!data.skip ? parseInt(data.skip) : 0)

        if (!!viewModel.SectionSearch.KeywordFilter) {
            viewModel.SectionSearch.KeywordFilter(data.keyword)
            viewModel.SearchKeyword(data.keyword)
        }

        if (!!viewModel.SectionSearch.SortOption) {
            if (!!data.sort && data.sort.length > 0) {
                viewModel.SectionSearch.SortOption(data.sort)
            } else {
                viewModel.SectionSearch.SortOption(viewModel.SectionSearch.SortOptions()[0].Key())
            }
        }

        var selectedcount = 0;

        if (!!viewModel.SectionSearch.FilterSection) {
            // Check regular filters
            for (var key in viewModel.SectionSearch.FilterSection.FilterGroups) {
                if (!!data[key] && data[key].length > 0) {
                    if (key == 'letters') {
                        ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.FilterGroups[key].Filters(), function (item) {
                            if (item.Name() == data[key]) {
                                item.IsSelected(true)
                                selectedcount++
                            }
                        })
                    } else {
                        ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.FilterGroups[key].Filters(), function (item) {
                            if (data[key].indexOf(item.ID()) >= 0) {
                                item.IsSelected(true)
                                selectedcount++
                            }
                            ko.utils.arrayForEach(item.ChildFilters(), function (childItem) {
                                if (data[key].indexOf(childItem.ID()) >= 0) {
                                    childItem.IsSelected(true)
                                    selectedcount++
                                }
                            })
                        })
                    }
                }
            }

            // Check single filters
            for (var key in viewModel.SectionSearch.FilterSection.SingleFilters) {
                if (typeof viewModel.SectionSearch.FilterSection.SingleFilters != "undefined" && typeof viewModel.SectionSearch.FilterSection.SingleFilters == "object") {
                    var filter = viewModel.SectionSearch.FilterSection.SingleFilters[key];
                    if (typeof filter != "undefined") {
                        if (filter.IsSelected()) {
                            selectedcount++;
                        }
                    }
                }
            }
        }
        viewModel.IsLoadingMore(true)
        viewModel.LoadDataFromServer();
    }

    // Extend View Model
    viewModel.IsSearchRunning = ko.observable(false)
    viewModel.IsLoadingMore = ko.observable(false);

    // Build query string based on filters
    viewModel.QueryString = function () {
        var queryString = ""
        if (!!viewModel.SectionSearch.KeywordFilter) {

            //add keyword filter
            if (!!viewModel.SectionSearch.KeywordFilter()) {
                queryString += (queryString != "" ? "&" : "?")
                queryString += "keyword=" + encodeURIComponent(viewModel.SectionSearch.KeywordFilter());
            }

            //add filter groups
            _.each(viewModel.SectionSearch.FilterSection.FilterGroups, function (filterGroup, key) {
                var selectedItems = new Array()
                ko.utils.arrayForEach(filterGroup.Filters(), function (item) {
                    if (item.IsSelected() == true) {
                        selectedItems.push(item.ID() != guidEmpty ? item.ID() : item.Name())
                    }
                    ko.utils.arrayForEach(item.ChildFilters(), function (childItem) {
                        if (childItem.IsSelected() == true) {
                            selectedItems.push(childItem.ID() != guidEmpty ? childItem.ID() : childItem.Name())
                        }
                    })
                })
                if (selectedItems.length > 0) {
                    queryString += (queryString != "" ? "&" : "?")
                    queryString += key + "=" + selectedItems.join()
                }
            })

            //add sinlge filters
            var singleFilters = ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.SingleFilters)
            _.each(singleFilters, function (filter, key) {
                var isSelected = ko.utils.unwrapObservable(filter.IsSelected)
                if (isSelected) {
                    queryString += (queryString != '' ? '&' : '?');
                    queryString += key.replace(' ', '') + "=" + filter.ID();
                }
            })

            //add date filters
            if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.DateFilterGroup)) {
                ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.DateFilterGroup.Filters(), function (filter) {
                    if (filter.IsSelected()) {
                        if (!filter.IsCustom() || (!!filter.From() && !!filter.To() && filter.From() < filter.To())) {
                            queryString += (queryString != '' ? '&' : '?')
                            queryString += 'dateoption=' + filter.FilterOption()
                            queryString += '&daterange=' + filter.From() + '-' + filter.To()
                        }
                    }
                })
            }

            //add qui tam filters
            if (!!ko.utils.unwrapObservable(viewModel.SectionSearch.FilterSection.QuiTamFilters)) {
                ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.QuiTamFilters.Filters(), function (filter) {
                    if (filter.IsSelected()) {
                        queryString += (queryString != '' ? '&' : '?')
                        queryString += 'quitam=' + true
                    }
                })
            }
        }

        queryString += (queryString != "" ? "&" : "?")
        queryString += "skip=" + viewModel.SectionSearch.Skip()
        if (!!viewModel.SectionSearch.SortOption) {
            queryString += (queryString != "" ? "&" : "?")
            queryString += "sort=" + viewModel.SectionSearch.SortOption()
        }
        if (!!viewModel.SectionSearch.ReloadFilters) {
            queryString += (queryString != "" ? "&" : "?")
            queryString += "reload=" + (!!viewModel.SectionSearch.ReloadFilters ? !!viewModel.SectionSearch.ReloadFilters() : "false")
        }
        queryString += (queryString != "" ? "&" : "?")
        queryString += "scroll=" + $(window).scrollTop()
        return queryString
    }

    // Run search by pushing new state
    viewModel.Search = function () {
        viewModel.HasMoreResults(false)
        viewModel.SectionSearch.Skip(0)
        var qs = viewModel.QueryString()
        history.pushState({ qs: qs }, document.title, qs)
        viewModel.LoadDataFromServer()
    }

    viewModel.expandFilter = function (data, e) {
        data.IsExpanded(!data.IsExpanded());

        e.preventDefault();
        e.stopPropagation();
    }

    // Load next page
    viewModel.LoadNextPage = function () {
        viewModel.IsLoadingMore(true)
        viewModel.SectionSearch.Skip(viewModel.GridData().length)
        var qs = viewModel.QueryString()
        history.pushState({ qs: qs }, document.title, qs)
        viewModel.LoadDataFromServer()
    }

    viewModel.SetFocusSearchResult = function(resultIndex) {
        var results = $('[data-bind="foreach: GridData()"] > div')

        if (resultIndex < results.length) {
            var result = results[resultIndex]
            var links = $(result).find('a')

            if (links.length > 0) {
                links[0].focus()
            }
        }

        if ($('.search-result-item').eq(resultIndex)[0] !== undefined){
            $('.search-result-item').eq(resultIndex)[0].focus();
        }
    }

    viewModel.ScrollToResults = function(resultIndex) {
      var results = $('[data-bind="foreach: GridData()"] > div')
      console.log(results.length)

      if (resultIndex < results.length) {
          var result = results[resultIndex]
          console.log(result)
          result.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }
    }

    // Load data from server
    viewModel.LoadDataFromServer = function (filterChange, isQuiTamSearch) {
        viewModel.IsSearchRunning(true)
        var $overlay = $('<div class="overlay-all is-shown-overlay"></div>').appendTo('body');
        if (filterChange) {
            viewModel.SectionSearch.Skip(0);
        }
        var viewModelToSend = GetRequestObject(viewModel.SectionSearch)

        $.ajax({
            url: option.serviceUrl,
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: ko.mapping.toJSON(viewModelToSend),
            cache: false,
            success: function (data) {
                var results = ko.observableArray()
                if (viewModel.SectionSearch.Skip() === 0 || filterChange) {
                    viewModel.ResultCount(0)
                    viewModel.GridData.removeAll()
                }
                ko.mapping.fromJS(data.GridData, {}, results)
                ko.utils.arrayForEach(results(), function (item) {
                    viewModel.GridData.push(item)
                })

                viewModel.ResultCount(data.ResultCount)
                viewModel.HasMoreResults(data.HasMoreResults)
                if (!!viewModel.SectionSearch.KeywordFilter)
                    viewModel.SectionSearch.KeywordFilter(data.SectionSearch.KeywordFilter)
                    viewModel.SearchKeyword(data.SectionSearch.KeywordFilter)
                viewModel.SectionSearch.SortGroups = data.SectionSearch.SortGroups
                if (!!data.SectionSearch.ReloadFilters)
                    viewModel.SectionSearch.ReloadFilters(data.SectionSearch.ReloadFilters)
                // if (!!viewModel.SectionSearch.FilterSection) {
                //     //viewModel.SetAppliedFilters()
                // }
                if (viewModel.SectionSearch.Take() == 0 && viewModel.SectionSearch.InitialPageSize == 0) {
                    viewModel.HasMoreResults(false)
                }
                if (!viewModel.HasAppliedFilters() && !viewModel.IsLoadingMore() && (window.location.href.endsWith('/people') || window.location.href.endsWith('/people/') || window.location.href.includes('/people?') || window.location.href.includes('/people/?'))) {
                    viewModel.GridData.removeAll()
                    viewModel.ResultCount(0)
                    viewModel.HasMoreResults(false)
                }
                viewModel.IsLoadingMore(false)
                viewModel.IsSearchRunning(false)
                if (typeof initializeReadMore != "undefined") {
                    initializeReadMore();
                }
                if (typeof setupOnClickForEmail != "undefined") {
                    setupOnClickForEmail();
                }

                $overlay.removeClass('is-shown-overlay');
                $overlay.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
                    $overlay.remove();
                });

                viewModel.ScrollToResults(viewModel.SectionSearch.Skip());
                //Accessibility: set focus to newest generated item on people search
                viewModel.SetFocusSearchResult(viewModel.SectionSearch.Skip());
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log(xhr)
                console.log("textStatus : " + textStatus)
                console.log("errorThrown : " + errorThrown)
                viewModel.IsSearchRunning(false)
                $overlay.removeClass('is-shown-overlay');
                $overlay.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
                    $overlay.remove();
                });
            }
        })
    }

    // Animations for new lit items
    viewModel.animateGridInsert = function (elem) {
        if (elem.nodeType === 1) {
            $(elem).hide().fadeIn(800)
        }
    }

    viewModel.animateGridDelete = function (elem) {
        if (elem.nodeType === 1) {
            $(elem).fadeOut(800, function () {
                $(elem).remove()
            })
        }
    }

    viewModel.SelectOptionChanged = function (obj, event) {
      if (event && event.originalEvent) { //user changed
          viewModel.Search()
      } else { // program changed
      }
    }

    // Clear page and reload filters
    viewModel.ClearPage = function () {
        viewModel.SectionSearch.ReloadFilters(true)
        viewModel.SectionSearch.ClearAllSectionSearch()
        viewModel.Search()
    }

    function clearFilterGroup() {
        if (this.hasOwnProperty('Filters')) {
            ko.utils.arrayForEach(this.Filters(), function (item) {
                item.IsSelected(false)
            })
        }
    }

    if (!!viewModel.SectionSearch.FilterSection) {
        for (var key in viewModel.SectionSearch.FilterSection.FilterGroups) {
            viewModel.SectionSearch.FilterSection.FilterGroups[key].clearFilterGroup = clearFilterGroup
        }
        if (!!viewModel.SectionSearch.FilterSection.DateFilterGroup) {
            viewModel.SectionSearch.FilterSection.DateFilterGroup.clearFilterGroup = clearFilterGroup
        }
        if (!!viewModel.SectionSearch.FilterSection.QuiTamFilters) {
            viewModel.SectionSearch.FilterSection.QuiTamFilters.clearFilterGroup = clearFilterGroup
        }
    }

    // Clear all filters
    viewModel.SectionSearch.ClearAllSectionSearch = function () {
        if (!!viewModel.SectionSearch.FilterSection) {
            if (typeof viewModel.SectionSearch.FilterSection == "object") {
                viewModel.SectionSearch.ClearAdditionalSectionSearch()
                if (viewModel.SectionSearch.FilterSection.FilterGroups.hasOwnProperty('letters')) {
                    ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.FilterGroups.letters.Filters(),
                        function (item) {
                            item.IsSelected(false);
                        });
                }
            }
        }
    }

    // Clear all filters except the letters
    viewModel.SectionSearch.ClearAdditionalSectionSearch = function () {
        if (!!viewModel.SectionSearch.FilterSection) {
            viewModel.SectionSearch.KeywordFilter('')
            viewModel.SearchKeyword('')
            for (var key in viewModel.SectionSearch.FilterSection.FilterGroups) {
                if (key != 'letters') {
                    ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.FilterGroups[key].Filters(), function (item) {
                        item.IsSelected(false)
                        ko.utils.arrayForEach(item.ChildFilters(), function (childItem) {
                            childItem.IsSelected(false)
                        })
                    })
                }
            }
            for (var key in viewModel.SectionSearch.FilterSection.singleFilters) {
                viewModel.SectionSearch.FilterSection.singleFilters[key].IsSelected(false);
                viewModel.SectionSearch.FilterSection.singleFilters[key].ID("");
            }
        }
    }

    function InitializeFilterGroups() {
        if (!!viewModel.SectionSearch.FilterSection) {
            for (var key in viewModel.SectionSearch.FilterSection.FilterGroups) {
                ko.utils.arrayForEach(viewModel.SectionSearch.FilterSection.FilterGroups[key].Filters(), function (item) {
                    item.IsSelected(item.IsSelected())
                    ko.utils.arrayForEach(item.ChildFilters(), function (childItem) {
                        childItem.IsSelected(childItem.IsSelected())
                    })
                })
            }
        }
    }

    // Clear Keyword Filter
    if (!!viewModel.SectionSearch.KeywordFilter) {
        viewModel.SectionSearch.KeywordFilter.ClearFilter = function () {
            viewModel.SectionSearch.KeywordFilter('')
        }
    }

    // Run thru all filters and initialize IsSelected
    InitializeFilterGroups();

    //set up typeahead
    if (!!option.typeaheadUrl && !!inputSelector && !!typeaheadSelector) {
        var typeahead = new Typeahead(option.typeaheadUrl, inputSelector, typeaheadSelector, viewModel)
    }
    // Apply Bindings
    ko.applyBindings(viewModel, pageContent.get(0))

    // Check for scroll in query string
    var qsParms = util.parseQueryString(window.location.search)
    var scrollPosition = 0
    if (!!qsParms.scroll && qsParms.scroll.length > 0) {
        scrollPosition = parseInt(qsParms.scroll)
    }
    $("html, body").animate({
        scrollTop: scrollPosition
    }, "slow")
}

module.exports = {
    init: init
}